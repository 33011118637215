.homepage-collection-section-slider-container {
    position: relative;
    overflow: hidden;
}

.homepage-collection-section-slider-image {
    object-fit: cover;
    height: 70vh;
    width: 80%;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 768px) {
    .homepage-collection-section-slider-image {
        width: 100%;
        height: 80%;
    }
    .benefit-card {
        width: 90% !important;
        flex-direction: column !important;
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }

    .small-text {
        font-size: 12px;
    }

    .slider-item-caption,
    .slider-comment-box {
        display: none !important;
    }
    .homepage-collection-section-slider-image {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
    }
}

.custom-arrow {
    font-size: 30px;
    width: 40px;
    height: 40px;
    background-color: rgba(128, 128, 128, 0.5);
    color: white;
    border-radius: 50%;
    z-index: 2;
}

.custom-arrow:hover {
    background-color: rgba(128, 128, 128, 0.8);
}

.slider-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70vh;
    overflow: hidden;
    opacity: 0.3;
    transition: opacity 1.5s;
    padding: 0 5%;
}

.slider-item-caption {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 1.5rem;
    background: linear-gradient(90deg, #1c3d5a -75%, #3a9d9b 350%);
    max-width: 20%;
    max-height: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    margin-right: 3%;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);
}

.slider-item.active-slide {
    opacity: 1;
}

.caption-title {
    font-size: 14px;
    font-weight: bold;
    color: white;
    text-align: left;
}

.small-text {
    font-size: 14px;
    margin: 0;
    color: white;
}

.info-button {
    transition: background-color 0.3s ease;
}

.info-button:hover {
    background-color: #ddd;
}

.slider-comment-box {
    position: absolute;
    left: 30%;
    top: 10%;
    bottom: 0;
    padding: 2rem;
    background: linear-gradient(90deg, #1c3d5a 0%, #3a9d9b 400%);
    color: white;
    max-width: 40%;
    max-height: fit-content;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    transform: translateX(50%);
    opacity: 0;
    border-radius: 50px;
    z-index: 2;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);
}

.slider-comment-box.visible {
    transform: translateX(0%);
    opacity: 1;
}

.image-0 {
    object-position: 100% 60%;
}
.image-1 {
    object-position: 100% 60%;
}
.image-2 {
    object-position: 100% 10%;
}
.image-3 {
    object-position: 100% 60%;
}
.image-4 {
    object-position: 100% 0%;
}

@media screen and (max-width: 768px) {
    .image-0 {
        object-position: 50% 100%;
    }
    .image-1 {
        object-position: 50% 100%;
    }
    .image-2 {
        object-position: 40% 100%;
    }
    .image-3 {
        object-position: 70% 100%;
    }
    .image-4 {
        object-position: 50% 100%;
    }
}

.overflow-hidden-slider {
    overflow-x: hidden;
}
