.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.global-text-colors {
    color: #e5e5e5;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.gradient-bg {
    background-image: linear-gradient(90deg, #1c3d5a 0%, #3a9d9b 100%);
}

.gradient-bg2 {
    background-image: linear-gradient(90deg, #1c3d5a -50%, #3a9d9b 150%);
}

.gradient-bg div {
    overflow: initial;
}

@media screen and (max-width: 768px) {
    .overflow-hidden-header {
        overflow: hidden;
    }
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.transparent-header {
    background-color: rgba(97, 118, 75, 0);
    transition: background-color 0.3s;
}

.header-shadow {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.image-rounded {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.collection-section {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.content-container {
    width: 80%;
    max-width: 1400px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.content-section {
    padding-top: 80px;
}

body {
    background-image: linear-gradient(90deg, #1c3d5a 0%, #3a9d9b 100%);
    overflow-x: hidden;
    width: 100%;
    height: 100vh;
    max-width: 100%;
}

.timeline-line {
    top: 50%;
}

.benefit-card {
    position: relative;
}

.bg-container-collection-cta {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 40%;
}

@media (max-width: 768px) {
    .bg-container-collection-cta {
        background-position: center 20%;
        background-size: 200%;
    }
    .hero-title {
        margin-left: -0.5rem;
    }
    .content-container {
        width: 90%;
        max-width: 1400px;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }
}

.bg-container-community-cta {
    background-size: cover;
    background-position: center 62%;
    background-repeat: no-repeat;

    @media (max-width: 768px) {
        background-position: 32% 70%;
        background-size: 200%;
    }
}

.convincing-section {
    overflow-x: hidden;
}

.restoration-section {
    overflow-x: hidden;
}

.collection-cta-section {
    overflow-x: hidden;
}

.collection-page {
    overflow-x: hidden;
}

.homepage {
    overflow-x: hidden;
}

.about-page {
    overflow-x: hidden;
}

.terms-of-use-page h2 {
    font-weight: bold;
    margin: 1rem 0;
}

.terms-of-use-page p {
    margin: 1rem 0;
}

.privacy-policy-page h2 {
    font-weight: bold;
    margin: 1rem 0;
}

.privacy-policy-page p {
    margin: 1rem 0;
}

.image-copyright-container {
    position: relative;
}

.image-copyright-overlay {
    position: absolute;
    bottom: 0;
    color: #f6f6f6;
    width: 100%;
    transition: 0.5s ease;
    opacity: 0.8;
    color: white;
    font-size: 16px;
    padding: 13px;
    text-align: center;
}

.image-wrapper > div {
    border-radius: 1rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.gridWithDivider::after {
    content: "";
    position: absolute;
    top: 15%;
    bottom: 0%;
    left: 61%;
    width: 1px;
    background: white;
}

.gridWithDivider {
    pointer-events: none;
}

.input ::placeholder {
    color: white;
    opacity: 1;
}

button.xmint-btn {
    background-color: rgb(34 197 94);
}

button.xmint-btn span {
    display: none;
}
button.xmint-btn:after {
    content: "Checkout";
    color: white;
}

.horizontally-aligned-checkout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
